<template>
  <div>
    <v-container fluid class="pa-6 ">
      <DatatableV2 :displayTitle="false" @openDrawerObject="openDrawerObject" :flat="false" :url="apiUrl" ref="opportunitiesTableList"/>
    </v-container>
  </div>
</template>

<script>
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'
export default {
  name: "OpportunitiesList",
  components: {
    DatatableV2
  },
  data() {
    return {
      apiUrl:"/todo/listOpportunities",
    };
  },
  watch: {
    todo: {
      deep:true,
      handler(val){
          if(!val.hasOwnProperty('id')){
            setTimeout(() => {
                this.$refs.opportunitiesTableList.readDataFromAPI();
            }, 100);
          }   
      }
           
    },
  },
  mounted() {
  },
  computed: {
    todo(){
        return this.$store.state.auth.todoForDrawer
    },
  },
  methods: {
    openDrawerObject(object){
        
        // } else {
            GenericDataService.getData('/todo/getExcerptByTodo?todo_id=' + object.id).then((response) => {
                this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: !this.$store.state.auth.openTodoActionDrawer, todo: object, excerpt : response.data.data});
            });   
        // }
    }
  },
};
</script>
