<template>
    <div class="wrapper">
        <v-expand-transition>
            <router-view />
        </v-expand-transition>
    </div>
</template>

<script>
export default {
    name: "Mission",
    components: {},
    data() {
        return {};
    },
    methods: {},
    computed: {},
    mounted() {},
};
</script>
