<template>
  <div>
    <v-container fluid class="pa-6 ">
      <DatatableV2 :displayTitle="false" @openDrawerObject="openDrawerObject" :flat="false" :url="apiUrl" ref="todoTableList"/>
    </v-container>
  </div>
</template>

<script>
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'
export default {
  name: "TodoList",
  components: {
    DatatableV2
  },
  data() {
    return {
      apiUrl:"/todo/list",
    };
  },
  watch: {
    todo: {
      deep:true,
      handler(val){
          if(!val.hasOwnProperty('id')){
            setTimeout(() => {
                this.$refs.todoTableList.readDataFromAPI();
            }, 100);
          }   
      }
           
    },
  },
  mounted() {
  },
  computed: {
    todo(){
        return this.$store.state.auth.todoForDrawer
    },
  },
  methods: {
    openDrawerObject(object){
        // Si c'est un Lead à traiter alors on ouvre directement le superlead
        if(["LEAD_TO_DEAL", "RDV_TO_CONFIRM", "FEEDBACK_RDV", "FEEDBACK_PASSAGE", "FEEDBACK_INSCRIPTION"].includes(object.type)) {
            let url = "superlead/getByRdvId?id=" + object.module_id;
            if(object.module === 'demand')
                url = "superlead/getByDemandId?id=" + object.module_id;

            GenericDataService.getData(url + ('&operation_id=' + (object.operation_id.id ? object.operation_id.id : object.operation_id))).then((response)=> {
                this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
            });
        } else {
            GenericDataService.getData('/todo/getExcerptByTodo?todo_id=' + object.id).then((response) => {
                this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: !this.$store.state.auth.openTodoActionDrawer, todo: object, excerpt : response.data.data});
            });   
        }
    }
  },
};
</script>
