<template>
  <div class="wrapper">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
  </div>
</template>
<script>

export default {
  name: "Opportunities",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  },
  mounted() {
  },
};
</script>
